import React from 'react'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { Create, Height, Link } from '@mui/icons-material'
import { Card, FormHelperText } from '@mui/material'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import { heIL } from '@mui/x-data-grid'
import { SnackbarContext } from '../../../context/Snackbar'
import CustomTextArea from '../../../components/common/CustomTextArea'
import {
  createAddInvCharge,
  createPaymentLinkApi,
  getAgent,
  getChargeMaster,
  getChargehead,
} from '../../../services/configService'
import { create } from '@mui/material/styles/createTransitions'
import AddInvChargeListing from './AddInvChargeListing'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,

  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
}

const AdditionalInvoiceCharges = () => {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [open, setOpen] = React.useState(false)
  const [render, setRender] = React.useState(false)
  const [error, setError] = React.useState('')
  const [chargeHead, setChargeHead] = React.useState([])
  const [chargeHeadId, setChargeHeadId] = React.useState(null)
  const [fieldError, setFieldError] = React.useState({})
  const [defaultAgent, setDefaultAgent] = React.useState(null)
  const [createdata, setCreatedata] = React.useState({
    foreign_amount: '',
    ex_rate: '',
  })

  async function getChargeheadlist() {
    const get_chargehead = await getChargeMaster()
    try {
      if (get_chargehead !== 204 && get_chargehead !== 401) {
        setChargeHead(get_chargehead?.data)
        console.log('get_chargehead?.data', get_chargehead?.data)
      }
    } catch {
      console.log('error')
    }
  }

  const handleChangeAgent = (e, v) => {
    setChargeHeadId(v?.id)
    const x = chargeHead.find((a) => a.id === v?.id)
    setDefaultAgent(x)
    setFieldError((prev) => ({ ...prev, chargeHead: '' }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setCreatedata((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handleCreatelink = async () => {
    if (!chargeHeadId || !createdata.ex_rate || !createdata.foreign_amount) {
      setSeverity('success')
      setMes('Additional Invoice charge created successfully')
      setOn(true)
      return
    }
    const data = {
      charge_id: chargeHeadId,
      ex_rate: Number(createdata.ex_rate), //createdata.ex_rate,
      foreign_amount: Number(createdata.foreign_amount),
      inr_amount: createdata.ex_rate * createdata.foreign_amount,
    }
    try {
      const res = await createAddInvCharge(data, setError)
      console.log('res', res)
      if (res !== 204 && res !== 406) {
        setCreatedata({
          foreign_amount: '',
          ex_rate: '',
        })
        setRender(true)
        setDefaultAgent(null)
        setOpen(false)

        setRender(true)
        setSeverity('success')
        setMes('Additional Invoice charge created successfully')
        setOn(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setCreatedata({
      foreign_amount: '',
      ex_rate: '',
    })
    setDefaultAgent(null)
  }
  React.useEffect(() => {
    getChargeheadlist()
  }, [])

  return (
    <DashboardLayout
      heading='Additional Invoice Charges'
      pathName='Additional Invoice Charges'
      button={true}
      btnIcon={<Create />}
      onClick={handleOpen}
      btnText='Create'
    >
      {/* <Paylisting render={render} setRender={setRender} /> */}
      <AddInvChargeListing render={render} setRender={setRender} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            color={'#184E81'}
          >
            Create Additional Invoice charge
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 0.1, mb: 2, fontWeight: 500 }}
          >
            Enter Details of Additional Invoice charge
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Autocomplete
                fullWidth
                className='form_style'
                disablePortal
                options={chargeHead}
                value={defaultAgent}
                getOptionLabel={(option) => `${option.charge_name}`}
                onChange={(e, v) => handleChangeAgent(e, v)}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Typography variant='subtitle2' color='initial'>
                      {option.charge_name}
                    </Typography>{' '}
                    &nbsp;
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Charge Head list'
                    error={fieldError?.chargeHead}
                    helperText={
                      fieldError?.chargeHead && 'Please select charge head'
                    }
                  />
                )}
                size='small'
              />
              <FormHelperText style={{ color: 'red' }}>
                {error && error['customer_id']}
              </FormHelperText>
            </Grid>
            <Grid item md={4}>
              <TextField
                type='number'
                className='form_style'
                label='Foreign Amount'
                size='small'
                fullWidth
                name='foreign_amount'
                value={createdata.foreign_amount}
                // error={error && error['amount']}
                onChange={(e) => handleChange(e, 'foreign_amount')}
              />
              <FormHelperText style={{ color: 'red' }}>
                {error && error['foreign_amount']}
              </FormHelperText>
            </Grid>
            <Grid item md={4}>
              <TextField
                type='number'
                className='form_style'
                label='Ex Rate'
                size='small'
                fullWidth
                name='ex_rate'
                value={createdata.ex_rate}
                // error={error && error['ex_rate'']}
                onChange={(e) => handleChange(e, 'ex_rate')}
              />
              <FormHelperText style={{ color: 'red' }}>
                {error && error['ex_rate']}
              </FormHelperText>
            </Grid>

            <Grid item md={4}>
              <TextField
                type='number'
                className='form_style'
                label='INR Amount'
                size='small'
                fullWidth
                name='inr_amount'
                value={createdata.foreign_amount * createdata.ex_rate}
                // error={error && error['amount']}
                // onChange={(e) => handleChange(e, 'inr_amount')}
                disabled
              />
              <FormHelperText style={{ color: 'red' }}>
                {error && error['inr_amount']}
              </FormHelperText>
            </Grid>

            <Grid item md={12}>
              <Button variant='contained' onClick={handleCreatelink}>
                save
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </DashboardLayout>
  )
}

export default AdditionalInvoiceCharges
