import React, { useContext } from 'react'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'

import {
  createOrganizationApi,
  deleteOrg,
  getReports,
  getToAndBccUser,
  updateCollaborator,
} from '../../../services/configService'
import dayjs from 'dayjs'

import { alpha, Box, gridClasses, Modal, styled } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { min } from 'lodash'

// import Userticketlisting from './Userticketlisting'
const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const ImapReports = () => {
  const [loader, setLoader] = React.useState(false)
  const [data, setData] = React.useState([])

  const getreportsData = async (value) => {
    try {
      const res = await getReports()

      if (res !== 204 && res !== 406) {
        console.log('res.data', res.data)
        setData(res.data)
      }
    } catch (error) {
      // console.log('error in generate quote ', error)
    } finally {
    }
  }

  const columns = [
    {
      field: 's_no',
      headerName: 'S no.',
      width: 2,
    },
    {
      field: 'First_Name',
      headerName: 'First Name',
      flex: 1,

      renderCell: (params) => {
        return <p>{params.row.First_Name}</p>
      },
    },
    {
      field: 'Last_Name',
      headerName: 'Last Name',
      flex: 1,

      renderCell: (params) => {
        return <p>{params.row.Last_Name}</p>
      },
    },
    {
      field: 'Email',
      headerName: 'Email',
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return <p>{params.row.Email}</p>
      },
    },
    {
      field: 'PAN_NO',
      headerName: 'Pan No.',
      flex: 1,

      renderCell: (params) => {
        return params.row.PAN_NO ? <p>{params.row.PAN_NO}</p> : <p>N.A</p>
      },
    },
    {
      field: 'Gst_no',
      headerName: 'GST No.',
      flex: 1,

      renderCell: (params) => {
        return params.row.Gst_no ? <p>{params.row.Gst_no}</p> : <p>N.A</p>
      },
    },
    {
      field: 'CompanyName',
      headerName: 'Company Name',
      flex: 1,

      renderCell: (params) => {
        return params.row.CompanyName ? (
          <p>{params.row.CompanyName}</p>
        ) : (
          <p>N.A</p>
        )
      },
    },
    {
      field: 'contact_number',
      headerName: 'Contact No.',
      flex: 1,

      renderCell: (params) => {
        return params.row.contact_number ? (
          <p>{params.row.contact_number}</p>
        ) : (
          <p>N.A</p>
        )
      },
    },
  ]

  React.useEffect(() => {
    getreportsData()
  }, [])
  return (
    <DashboardLayout heading='Mailing Process' pathName='Mailing Process'>
      <Box sx={{ height: '80vh', width: '100%' }}>
        <Modal
          open={loader}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='loader-container'>
            <div class='dot-spinner'>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
            </div>
            <p
              style={{
                marginLeft: '10px',
                fontWeight: '500',
                marginTop: '10px',
              }}
            >
              Please wait!!
            </p>
          </div>
        </Modal>

        <StripedDataGrid
          rows={data.map((item, index) => ({
            ...item,
            s_no: index + 1,
          }))}
          getRowId={(row) => row.s_no}
          columns={columns}
          // initialState={{
          //   pagination: { paginationModel: { pageSize: 50, page: 0 } },
          // }}
          // rowCount={archived === true ? dataCloseCount : dataOpenCount} // Define the total number of rows
          // paginationMode='server' // Enables controlled pagination
          // onPageChange={(page) => {
          //   setPagination(page)
          //   // setFliterDataPagination(page)
          // }}
          // onPaginationModelChange={(params) => {
          //   setPagination(params.page)
          //   // setFliterDataPagination(params.page)
          // }}
          // pageSizeOptions={[10, 20, 50]} // Set the available page size options
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          getRowClassName={(params) =>
            params.row.is_mapped == 1
              ? 'void'
              : params.indexRelativeToCurrentPage % 2 === 0
              ? 'even'
              : 'odd'
          }
          disableRowSelectionOnClick
        />
        {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Makepaymentsuccess paymentdetails={paymentdetails} />
        </Box>
      </Modal> */}
      </Box>
    </DashboardLayout>
  )
}

export default ImapReports
