import React from 'react'
import {
  Box,
  Typography,
  Paper,
  Button,
  Modal,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Article, Download, VisibilityTwoTone } from '@mui/icons-material'
import { downloadAttachment } from '../../utils/common'

function getFileTypeInfo(filename) {
  const extension = filename.split('.').pop()?.toLowerCase()
  const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${filename}&embedded=true`
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return {
        icon: (
          <img src='/images/image-logo.png' style={{ width: 56, height: 56 }} />
        ),
        label: 'Image',
        link: filename,
        download: filename,
        type: 'image',
      }
    case 'pdf':
      return {
        icon: <img src='/images/pdf.png' style={{ width: 56, height: 56 }} />,
        label: 'PDF document',
        link: filename,
        download: filename,
        type: 'pdf',
      }
    case 'doc':
    case 'docx':
      return {
        icon: <img src='/images/words.png' style={{ width: 56, height: 56 }} />,
        label: 'Word document',
        link: googleDocsViewerUrl,
        download: filename,
        type: 'doc',
      }
    case 'xls':
    case 'xlsx':
    case 'csv':
      return {
        icon: <img src='/images/excel.png' style={{ width: 56, height: 56 }} />,
        label: 'Spreadsheet',
        link: filename,
        download: filename,
        type: 'csv',
      }
    default:
      return {
        icon: <Article style={{ width: 56, height: 56, color: 'gray' }} />,
        label: 'Document',
        link: filename,
        download: filename,
        type: 'doc',
      }
  }
}

function AttachmentViewer({ filename, fileType }) {
  const { icon, label, link, download, type } = getFileTypeInfo(filename)

  const [previewUrl, setPreviewUrl] = React.useState('')
  const [open, setOpen] = React.useState(false)
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setPreviewUrl('')
  }
  const handleOpenPreviewPdf = (url) => {
    setPreviewUrl(url) // Set the URL of the PDF to preview
    setOpen(true)
  }

  function truncateText(filename, maxWords = 5) {
    if (!filename) return 'File name not available' // Handle cases where the filename is undefined or null

    // Split the filename into an array of words
    const words = filename.split(' ')

    // Truncate if the number of words exceeds the limit
    return words.length > maxWords
      ? words.slice(0, maxWords).join(' ') + '...'
      : filename
  }

  return (
    <Paper
      elevation={1}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        // padding: 1,
        borderRadius: 1,
        paddingRight: 2,
        border: '1px solid #e0e0e0',
        backgroundColor: 'white',
        transition: 'background-color 0.2s',
        '&:hover': { backgroundColor: '#f9f9f9' },
      }}
    >
      <div
        style={{ padding: 2, display: 'flex', alignItems: 'center', gap: 4 }}
      >
        {icon}
        <Box onClick={() => type !== 'csv' && handleOpenPreviewPdf(link)}>
          <Tooltip
            title={
              filename
                ?.split('/')
                ?.pop() // Extract the file name
                ?.replace(/-+\+/g, '') // Remove occurrences of "-+"
                ?.replace(/\+/g, ' ') // Replace all "+" symbols with a space
                ?.replace(/%[0-9a-fA-F]{2}/g, ' ') // Remove URL-encoded characters (e.g., %20)
                ?.replace(/\d+/g, '') // Remove all numeric values
                ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                ?.trim() // Remove leading/trailing spaces
            }
          >
            <Typography
              variant='body1'
              sx={{
                '&:hover': {
                  color: 'green', // Change text color on hover
                  textDecoration: 'underline', // Add underline on hover
                  cursor: 'pointer', // Change cursor to pointer on hover
                },
              }}
              fontWeight={800}
              fontSize={13}
            >
              {truncateText(
                filename
                  ?.split('/')
                  ?.pop() // Extract the file name
                  ?.replace(/-+\+/g, '') // Remove occurrences of "-+"
                  ?.replace(/\+/g, ' ') // Replace all "+" symbols with a space
                  ?.replace(/%[0-9a-fA-F]{2}/g, ' ') // Remove URL-encoded characters (e.g., %20)
                  ?.replace(/\d+/g, '') // Remove all numeric values
                  ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                  ?.trim() // Remove leading/trailing spaces
              )}
            </Typography>
          </Tooltip>
          <Typography variant='body2' fontWeight={500} color='text.secondary'>
            {fileType || label}
          </Typography>
        </Box>
      </div>
      <IconButton
        onClick={() =>
          downloadAttachment(
            download,
            `${filename
              ?.split('/')
              ?.pop() // Extract the file name
              ?.replace(/-+\+/g, '') // Remove occurrences of "-+"
              ?.replace(/\+/g, ' ') // Remove all "+" symbols
              ?.replace(/%[0-9a-fA-F]{2}/g, '') // Remove URL-encoded characters (e.g., %20)
              ?.replace(/\d+/g, '') // Remove all numeric values
              ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
              ?.trim() // Remove leading/trailing spaces
              ?.toLowerCase()}`
          )
        }
      >
        <Download color='info' />
      </IconButton>
      <Modal open={open} onClose={() => handleClose()}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
          }}
        >
          <iframe
            src={previewUrl}
            title='PDF Preview'
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
            }}
          ></iframe>
        </Box>
      </Modal>
    </Paper>
  )
}

export default AttachmentViewer
