import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'

import Card from '@mui/material/Card'
import { Create } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Updatemappinglisting from './Updatemappinglisting'

const Updatemappedmain = () => {
  const { state } = useLocation()

  return (
    <Card sx={{ height: '100%' }} elevation={0}>
      <DashboardLayout
        // heading='Update Mapping'
        pathName='Back to Receipt'
        pathName1={'Update Mapping'}
        pathLink={'/receipt'}
      >
        <Updatemappinglisting state={state} />
      </DashboardLayout>
    </Card>
  )
}
export default Updatemappedmain
